// Angular
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
// RxJS
import { ActivatedRoute, Router } from "@angular/router";
import { A3dnUserService, ErrorPanelService } from "a3dn-shared-lib";
import { NEVER, Observable } from "rxjs";
import "rxjs/add/operator/catch";
import { tap } from "rxjs/operators";
@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private a3dnError: ErrorPanelService,
		private route: ActivatedRoute,
		private userService: A3dnUserService
	) {}

	isExternalUser: boolean = false;
	isEmbeddedSurvey: boolean = false;
	isGuest: boolean = false;

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let isBlobUrl = request.url.includes("blob.core.windows.net/");
		let isOpenApiUrl = request.url.includes("opendatasoft");
		this.isExternalUser = this.route.snapshot.firstChild.url[0]?.path == "q";
		this.isEmbeddedSurvey = this.route.snapshot.firstChild.url[0]?.path == "e";
		let jwtSurvey = localStorage.getItem("jwtSurvey");
		let jwtEmbeddedSurvey = localStorage.getItem("jwtEmbeddedSurvey");

		this.isGuest = this.route.snapshot.firstChild?.url[0]?.path == "g";
		let jwtGuest = localStorage.getItem("jwtGuest");
		if (jwtGuest && this.isGuest) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${jwtGuest}`,
				},
			});
		} else if (jwtSurvey && this.isExternalUser) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${jwtSurvey}`,
				},
			});
		} else if (jwtEmbeddedSurvey && this.isEmbeddedSurvey) {
			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${jwtEmbeddedSurvey}`,
				},
			});
		}

		let jwt = localStorage.getItem("jwt") || this.userService.jetonApi;
		if (
			jwt &&
			!isBlobUrl &&
			!isOpenApiUrl &&
			!this.isExternalUser &&
			!this.isGuest &&
			!this.isEmbeddedSurvey
		) {
			if (jwt == this.userService.userToken || jwt == this.userService.jetonApi)
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${jwt}`,
					},
				});
			else {
				this.router.navigate(["a", "dashboard"]);
				window.location.reload();

				return NEVER;
			}
		}
		return next.handle(request).pipe(
			tap(
				(x) => x,
				(err) => {
					let status = err.status;

					if (this.isExternalUser || this.isGuest) {
						if (err instanceof HttpErrorResponse) {
							if (status === 401 || status === 403) {
								this.a3dnError.openPanel(
									"Une erreur est survenue. Veuillez nous contacter pour résoudre le problème."
								);
							}
						}

						return;
					}

					if (err instanceof HttpErrorResponse) {
						if (status === 401 || status === 403) {
							localStorage.clear();
							if (this.router.url.includes("a/super-admin"))
								this.router.navigateByUrl("/sa/login");
							else this.router.navigateByUrl("/login");
						} else this.httpErrorHandler(err);
					}
				}
			)
		);
	}

	httpErrorHandler(error: HttpErrorResponse) {
		if (error) {
			if (this.isExternalUser) {
				this.a3dnError.openPanel(
					"Veuillez nous contacter car une erreur est survenue lors de l'ouverture de votre questionnaire."
				);
				return;
			}
			if (error.status === 404 && error.error?.result?.avecRedirection) {
				this.router.navigate(["a", "dashboard"]);
			}

			if (error.status == 400 || error.status == 500) {
				let errorMessage =
					"Nous sommes désolés,  quelque chose s'est mal déroulée. " +
					"Notre équipe technique va investiguer le sujet ...";
				if (error.error?.result?.result?.champsErrones?.length) {
					let fields: string = error.error?.result?.champsErrones.join(", ");
					errorMessage =
						"Quelque chose s'est mal déroulée. " +
						"Veuillez vérifier les champs suivants : " +
						fields;
				}
				this.a3dnError.openPanel(errorMessage);
			}
		}
	}
}
